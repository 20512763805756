@font-face {
  font-family: 'El Messiri';
  font-style: normal;
  font-weight: normal;
  src: local('El Messiri'), url('./font.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #3b3a3a;
  min-height: 100vh;
  font-family: "El Messiri";
}

.tabs {
  display: flex;
  flex-direction: row;
}

.a-tab {
  flex-grow: 1;
  display: block;
}

.site-link {
  color: #61dafb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
